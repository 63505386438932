<template>
  <Layout>
    <Head :title="`${playlist.title}`" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-primary-500 hover:text-primary-800"
        :href="$routes.playlists()"
      >
        Playlists
      </Link>
      <span class="font-medium text-primary-400">/</span>
      {{ playlist.title }}
    </h1>
    <h3 v-if="needsConfirmation" class="text-lg mb-2 max-w-prose text-red-700 flex items-center">
      Please confirm your email before proceeding!
      <Link :href="$routes.confirm_resend()" class=" ml-auto text-sm">Resend Confirmation Email</Link>
    </h3>
    <PlaylistMenu :playlist />
    <div class="flex flex-col xl:flex-row gap-6">
      <div v-if="!bulk">
        <h2 class="text-xl mb-4 max-w-prose">
          Use the form below to invite anyone to contribute a single recorded or uploaded video to <strong>{{ playlist.title }}</strong>; no signup needed!
        </h2>
        <InvitationForm v-model="form" class="bg-white rounded shadow max-w-prose" :quick-add="true" @submit="submit">
          <div
            class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
          >
            <div class="text-primary-700 hover:text-primary-600 cursor-pointer" @click="bulk=true">
              Bulk Import
            </div>

            <LoadingButton
              :loading="form.processing"
              :disabled="needsConfirmation"
              :title="needsConfirmation ? 'Please confirm your email to invite': 'Import'"
              class="btn-primary ml-auto"
              type="submit"
            >
              Invite
            </LoadingButton>
          </div>
        </InvitationForm>
      </div>
      <div v-else>
        <h2 class="text-xl mb-4 max-w-prose">
          Use the form below to invite anyone to contribute a single recorded or uploaded video to <strong>{{ playlist.title }}</strong>; no signup needed!
        </h2>
        <div class="bg-white rounded shadow max-w-prose">
          <div class="gap-6 flex flex-wrap w-full p-8">
            <span class="form-label">
              Download the sample CSV file below, and replace the example row with your first contributor.  Add as many rows as you like.
              <div class="mt-2 p-4 bg-gray-200">
                <img :src="ImportImage" alt="Example CSV Image">
                <span class="text-xs mt-2">Pictured: Example CSV</span>
              </div>
              <br>
              <span class="text-sm text-secondary">The header row with column names and the invitation email are required.</span>
            </span>
            <a class="btn-secondary flex gap-1" href="/InviteImport.csv" download>
              Sample CSV File <DocumentArrowDownIcon class="size-5" />
            </a>
            <FileInput
              v-model="bulkForm.bulk.file"
              :errors="form.errors.file"
              label="File"
              class="w-full"
            />
            <div v-for="(error, index) in $page.props.errors" :key="index" class="text-xs text-red-700">
              {{ error }}
            </div>
          </div>

          <div
            class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
          >
            <div class="text-primary-700 hover:text-primary-600 cursor-pointer" @click="bulk=false">
              Invite Single
            </div>

            <LoadingButton
              :loading="bulkUploading"
              :disabled="needsConfirmation"
              :title="needsConfirmation ? 'Please confirm your email to invite': 'Import'"
              class="btn-primary ml-auto"
              type="submit"
              @click="bulkUpload"
            >
              Import
            </LoadingButton>
          </div>
        </div>
        <div class="mt-4">
          <Link class="btn-secondary inline-block" :href="$routes.onboard_media()">
            Next <ArrowRightIcon class="size-4 inline" /> Add Media to {{ playlist.title }}
          </Link>
        </div>
      </div>
      <div>
        <h3 class="font-semibold mb-2">
          Need more help?
        </h3>
        <p class="text-sm max-w-prose mb-4">
          Assign advanced collaborator roles and fine-tune permissions on the
          <Link :href="$routes.collaboration_index()" class="text-primary-700">
            collaboration
          </Link>
          page.
        </p>
        <div class="flex flex-wrap gap-2">
          <div
            v-for="invite in $page.props.invitations.filter((i)=>!i.authored_media?.length)"
            :key="invite.id"
            class="flex flex-row bg-white shadow rounded focus-within:bg-gray-100 hover:bg-gray-100 items-center w-fit"
          >
            <div>
              <div
                class="px-4 py-2 text-sm flex flex-col"
              >
                <div>{{ invite.first_name }} {{ invite.last_name }}</div>
                <div class="text-xs">
                  {{ invite.email }}
                </div>
              </div>
            </div>
            <div class="ml-auto">
              <div
                class="py-2 text-sm flex flex-col"
              >
                <span v-if="!!invite.authored_media?.length" class="text-green-700 text-xs flex ml-2">Contributed<CheckIcon class="size-4 mx-2" /></span>
                <div v-else class="flex items-center">
                  <button class="relative mx-2" title="Resend Invitation" @click="resend(invite.code)">
                    <EnvelopeIcon class="size-6 text-primary" />
                    <ArrowPathIcon class="size-4 absolute bottom-0 right-0 bg-green-100 rounded-full p-0.5" />
                  </button>
                  <DestroyInvitation :invitation="invite">
                    <TrashIcon
                      class="size-5 text-red-700 ml-auto"
                    />
                  </DestroyInvitation>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap gap-2 pt-2">
          <div
            v-for="invite in $page.props.invitations.filter((i)=>i.authored_media?.length)"
            :key="invite.id"
            class="flex flex-row bg-white shadow rounded focus-within:bg-gray-100 hover:bg-gray-100 items-center w-fit"
          >
            <div>
              <div
                class="px-4 py-2 text-sm flex flex-col"
              >
                <div>{{ invite.first_name }} {{ invite.last_name }}</div>
                <div class="text-xs">
                  {{ invite.email }}
                </div>
              </div>
            </div>
            <div class="ml-auto">
              <div
                class="py-2 text-sm flex flex-col"
              >
                <span v-if="!!invite.authored_media?.length" class="text-green-700 text-xs flex ml-2">Contributed<CheckIcon class="size-4 mx-2" /></span>
                <DestroyInvitation v-else :invitation="invite">
                  <TrashIcon
                    class="size-5 text-red-700 ml-auto"
                  />
                </DestroyInvitation>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, Link, router, useForm, usePage } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue'
import * as $routes from '@/routes';
import LoadingButton from '../../Shared/LoadingButton.vue';
import PlaylistMenu from './Components/PlaylistMenu.vue';
import InvitationForm from '../Collaboration/Form.vue';
import {
  ArrowPathIcon,
  ArrowRightIcon,
  CheckIcon,
  DocumentArrowDownIcon,
  EnvelopeIcon,
  TrashIcon,
} from '@heroicons/vue/24/outline';
import DestroyInvitation from '../Invitations/Destroy.vue';
import ImportImage from '../../../../public/img/ImportCSV.png';
import FileInput from '../../Shared/FileInput.vue';
import { ref } from 'vue';

const props = defineProps({
  playlist: {
    type: Object,
    required: true,
  },
})

const bulk = ref(false)
const bulkUploading = ref(false)

const form = useForm({
  invitation: {
    permissions: {
      media: { view: false, manage: false, upload: false},
      playlists: [
        { id: "default", view: false, manage: false, upload: false},
        { id: props.playlist.hashid, view: false, manage: false, upload: true}
      ]
    },
    invitable_type: 'Playlist',
    invitable_id: props.playlist.id
  }
})

const bulkForm = useForm({
  bulk: {
    file: null,
    invitable_type: 'Playlist',
    invitable_id: props.playlist.id
  }
})

const submit = () => {
  form.post($routes.invitations(), {
    onSuccess: () => {
      form.reset('invitation');
    },
  });
}

const bulkUpload = () => {
  bulkUploading.value = true
  bulkForm.post($routes.invitations_import(), {
    onSuccess: () => {
      bulkForm.reset();
      bulkUploading.value = false
    },
    onError: () => {
      bulkUploading.value = true
    }
  })
}

const needsConfirmation = usePage().props.auth.user.role !== 'admin' && (!usePage().props.auth.user.confirmed && !usePage().props.auth.user.storage_sub)

const resend = (code) => {
  router.visit($routes.invitation_resend_notifications(code))
}
</script>
