<template>
  <div class="gap-6 flex w-full p-8 flex-col">
    <DropZone
      ref="dropZone"
      :form-data="formData"
      :multiple="true"
      :route="$routes.uploads()"
      @processing="dzProcessing = true"
      @success="complete()"
    />
  </div>
  <div
    class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
  >
    <LoadingButton
      :loading="dzProcessing"
      class="btn-primary ml-auto"
      @click="submit"
    >
      Upload Media
    </LoadingButton>
  </div>
</template>

<script setup>
import LoadingButton from '@/Shared/LoadingButton.vue';
import {  usePage } from '@inertiajs/vue3';
import * as $routes from '@/routes';
import { computed, ref } from 'vue';
import DropZone from '../../Shared/DropZone.vue';

const emit = defineEmits(['success']);

const dropZone = ref()
const dzProcessing = ref(false)

const libraryOptions = computed(() => {
  let fOs = usePage().props.libraryOptions.filter((option) => option.value !== 'all')
  if (!fOs.length) {
    return []
  }
  fOs[0].selected = true
  for (let i = 0; i<fOs.length; i++) {
    if (fOs[i].value === 'mine') {
      fOs[i].value = null
    }
  }
  return fOs
})

const formData = {
  upload: {
    name: null,
    file: null
  },
  media_library_user_id: usePage().props.filterOptions?.length  ? libraryOptions.value.filter((option) => option.value !== 'all')[0].value : null,
  signed_blob_id: null
}

const submit = () => {
    dropZone.value.submit();
}

const complete = () => {
  dzProcessing.value = false
  emit('success')
}
</script>
