<template>
  <div class="flex flex-col items-center w-full">
    <div class="flex flex-col lg:flex-row w-full justify-between gap-4">
      <div class="flex flex-col lg:w-3/5 gap-3 max-w-prose rounded bg-white shadow px-6 py-4">
        <div class="flex sm:flex-row flex-col gap-0 sm:gap-4 items-center">
          <div class="text-xl font-semibold">
            {{ $page.props.auth.user.storage_plan.name }}
            <span class="text-xs">(<Status />)</span>
            —
            <span :class="{'line-through text-base': !!$page.props.customer.discount?.coupon}">
              <DollarAmount :amount="isMonthly ? $page.props.auth.user.storage_plan.monthly_price : $page.props.auth.user.storage_plan.annual_price" />
            </span>
            <span v-if="!!$page.props.customer.discount?.coupon" class="ml-2 text-green-700">
              <DollarAmount
                :amount="isMonthly ?
                  Math.floor($page.props.auth.user.storage_plan.monthly_price * ($page.props.customer.discount?.coupon.percent_off / 100) * 100) / 100 :
                  Math.floor($page.props.auth.user.storage_plan.annual_price * ($page.props.customer.discount?.coupon.percent_off / 100) * 100) / 100"
              />
            </span>/{{ isMonthly ? 'month' :'year' }}
          </div>
          <div v-if="canChangePlan()">
            <ChangePlan>
              <span class="text-xs text-primary-700">Change Plan</span>
            </ChangePlan>
          </div>
        </div>

        <template v-if="$page.props.auth.user.storage_sub.canceled">
          <p class="font-bold">
            Your subscription is set to cancel on <DateString month-format="long" :date="$page.props.auth.user.storage_sub.ends_at" />.
          </p>
          <p>You will retain your membership benefits and may reactivate until your subscription expires.</p>
          <div>
            <Link class="btn-primary inline-block" :href="$routes.subscriptions_resume()">
              Reactivate
            </Link>
          </div>
        </template>
        <template v-else-if="$page.props.auth.user.storage_sub.status === 'incomplete'">
          Your subscription is incomplete!

          Please add a valid payment method.
          <ChangePayment>
            <span class="btn-secondary text-xs ">
              Change Payment
            </span>
          </ChangePayment>
          <Link class="btn-primary" :href="$routes.subscriptions_retry()">
            Retry Payment
          </Link>
        </template>
        <template v-else>
          <p class="text-xs mb-2">
            Thanks for being a member since <DateString month-format="long" :date="$page.props.auth.user.storage_sub.created_at" />!
          </p>
          <Promo />
          <div v-if="$page.props.auth.user.past_due_and_waiting_for_update_payments" class="text-red-700">
            <Link class="btn-primary" :href="$routes.subscriptions_retry()">
              Retry Payment
            </Link>
          </div>
          <div class="flex flex-wrap justify-between items-center gap-1 mt-4">
            <p class="text-sm">
              Your next billing date is
              <DateString month-format="long" :date="$page.props.auth.user.storage_sub.current_period_end" />
            </p>
            <div class="flex items-center justify-between w-full">
              <span class="text-xs flex items-center">
                <CardIcon :brand="$page.props.subPaymentMethod.data.brand" class="h-6 mr-2" />
                {{ $page.props.subPaymentMethod.data.last4 }} |
                {{ $page.props.subPaymentMethod.data.exp_month }}/{{ $page.props.subPaymentMethod.data.exp_year }}
              </span>
              <ChangePayment>
                <span class="text-xs text-primary-700">
                  Change Payment
                </span>
              </ChangePayment>
            </div>
          </div>
          <Cancel>
            <span class="text-xs text-red-700">Cancel</span>
          </Cancel>
        </template>

        <div v-if="$page.props.errors" class="flex justify-between items-center">
          <div v-for="(error, index) in $page.props.errors?.pay" :key="index" class="text-xs font-medium text-red-700 mr-12">
            {{ error }}
          </div>
        </div>
      </div>
      <div class="flex flex-col lg:w-2/5 gap-2 max-w-prose">
        <div class="relative flex flex-col bg-primary-700 shadow p-5 text-white rounded">
          <header class="pb-2">
            <div>
              As a {{ $page.props.auth.user.storage_plan.name }} member you get:
            </div>
          </header>
          <ul role="list" class="text-sm space-y-2 text-left">
            <li class="flex items-center space-x-2">
              <CheckIcon class="size-4" />
              <span><span class="font-semibold">{{ $page.props.auth.user.storage_plan.storage_minutes }}</span> minutes of storage</span>
            </li>
            <li class="flex items-center space-x-2">
              <CheckIcon class="size-4" />
              <span v-if="$page.props.auth.user.storage_plan.playlists !== -1">Playlists: <span class="font-semibold">{{ $page.props.auth.user.storage_plan.playlists }}</span></span>
              <span v-else><span class="font-semibold">Unlimited Playlists</span></span>
            </li>
            <li v-if="!!$page.props.auth.user.storage_plan.collaborators" class="flex items-center space-x-2">
              <CheckIcon class="size-4" />
              <span>Collaborators: <span class="font-semibold">{{ $page.props.auth.user.storage_plan.collaborators }}</span></span>
            </li>
            <li v-if="!$page.props.auth.user.storage_plan.ad_supported" class="flex items-center space-x-2">
              <CheckIcon class="size-4" />
              <span>Ad-Free</span>
            </li>
            <li v-else class="flex items-center space-x-2">
              <span>Limited Ads</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import DateString from '@/Shared/DateString.vue';
import CardIcon from '@/Shared/CardIcon.vue';
import { CheckIcon} from '@heroicons/vue/24/outline';
import Cancel from './Cancel.vue';
import { Link, usePage } from '@inertiajs/vue3';
import ChangePlan from './ChangePlan.vue';
import * as $routes from '@/routes';
import ChangePayment from './ChangePayment.vue';
import Promo from './Promo.vue';
import DollarAmount from '../../Shared/DollarAmount.vue';
import Status from './Components/Status.vue'

const canChangePlan = () => !usePage().props.auth.user.storage_sub.canceled && usePage().props.plans.length > 1
const isMonthly =  usePage().props.auth.user.storage_plan.monthly_stripe_price_id === usePage().props.auth.user.storage_sub.processor_plan
</script>
