<template>
  <div id="ad-container"></div>
</template>

<script>
import { usePage } from '@inertiajs/vue3';

export default {
  name: 'AdComponent',
  mounted() {
    this.loadAd();
  },
  methods: {
    loadAd() {
      if ( !usePage().props.auth.user.storage_plan || usePage().props.auth.user.storage_plan?.ad_supported ){
        let adContainer = document.getElementById('ad-container');

        let ins = document.createElement('ins');
        ins.className = 'adsbygoogle';
        ins.style.display = 'block';
        ins.setAttribute('data-ad-client', 'ca-pub-1102490854053247');
        ins.setAttribute('data-ad-slot', '2269324439');
        ins.setAttribute('data-adtest', 'on');
        ins.setAttribute('data-ad-format', 'auto');
        ins.setAttribute('data-full-width-responsive', 'true');
        adContainer.appendChild(ins);

        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    },
  }
}
</script>
