<template>
  <Layout class="max-w-screen-full min-h-screen m-auto py-10" :style="`background-color: ${playlist.customizations.background_color}`">
    <Head :title="playlist.title" />
    {{  }}
    <div class="max-w-screen-xl mx-auto">
      <h1 class="mb-8 text-3xl font-bold text-center" :style="`color: ${makeColorReadable(tinycolor(playlist.customizations.background_color).spin(180),playlist.customizations.background_color)}`">
        {{ playlist.title }}
      </h1>
      <template v-if="playlistData.length">
        <VideoPlayer
          :class="['w-full','h-[75vh]','video-player', 'vjs-big-play-centered', { loading: !state }]"
          :sources="mediaConfig.sources"
          :poster="mediaConfig.poster"
          :tracks="mediaConfig.tracks"
          :autoplay="config.autoplay"
          :loop="config.loop"
          crossorigin="anonymous"
          playsinline
          :height="config.height"
          :volume="config.volume"
          :controls="true"
          :muted="false"
          @ended="next()"
          @error="e=>handleError(e)"
          @mounted="handleMounted"
        />
        <PlayerPlaylist class="playlist" :playlist-data="playlistData" :index="playMediaIndex" @update:index="handleMediaChange" />
      </template>
      <div v-else class="text-center">
        No media yet.
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head } from '@inertiajs/vue3';
import Layout from '@/Layouts/Minimal.vue'
import { computed, nextTick, ref } from 'vue';
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import PlayerPlaylist from '../../Shared/PlayerList.vue';
import tinycolor from "tinycolor2";

const props = defineProps({
  playlist: {
    type: Object,
    required: true,
  }
})

const playlistData = props.playlist.playlist_uploads.map( (i) =>  ({
  name: i.upload.name,
  description: i.upload.name,
  duration: i.upload.duration,
  poster: i.upload.preview,
  sources: [
    { src: i.upload.url_playable, type: i.upload.content_type },
  ],
  url_downloadable: i.upload.url_downloadable
}))

const player = ref()
const state = ref()
const config = ref({
  autoplay: true,
  height: '80vh',
  volume: 1,
  playbackRate: 1,
  loop: false,
  preload: 'auto'
})

const playMediaIndex = ref(0)
const mediaConfig = computed(() => ({
  sources: playlistData[playMediaIndex.value]?.sources,
  poster: playMediaIndex.value === 0 ? playlistData[0]?.poster : null,
  tracks: (playlistData[playMediaIndex.value]?.textTracks) ?? []
}))

const handleMounted = (payload) => {
  console.log('Advanced player mounted', payload)
  console.log(mediaConfig.value)
  state.value = payload.state
  player.value = payload.player
  if (playlistData.length > 1) {
    let prevButton = player.value.controlBar.addChild('button', {
      clickHandler: function () {
        prev()
      },
      controlText: 'Previous'
    }, 0)
    prevButton.addClass('vjs-icon-previous-item')
    let nextButton = player.value.controlBar.addChild('button', {
      clickHandler: function () {
        next()
      },
      controlText: 'Next'
    }, 2)
    nextButton.addClass('vjs-icon-next-item')
  }
}

const handleMediaChange = (index) => {
  playMediaIndex.value = index
  nextTick(() => {
    player.value?.play().catch((e)=> {
      handleError(e)
    })
  })
}

const next = () => {
  if (playMediaIndex.value+1 < playlistData.length) {
    handleMediaChange(playMediaIndex.value+1)
  }
}

const prev = () => {
  if (playMediaIndex.value > 0) {
    handleMediaChange(playMediaIndex.value-1)
  }
}

const handleError = (e) => {
  console.log('An error occurred', e)
}

const makeColorReadable = (color, background) => {
  const c = tinycolor(color);
  const bg = tinycolor(background);
  const isBackgroundDarker = tinycolor.readability(bg, 'white') > tinycolor.readability(bg, 'black');
  while (tinycolor.readability(bg, c) < 4.5 && c.getLuminance() >= 0 && c.getLuminance() <= 1)
  {
    if (isBackgroundDarker) {
      c.lighten(5);
    } else {
      c.darken(5);
    }
  }
  return c.toHexString();
};
</script>
