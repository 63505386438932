<template>
  <div class="border-b border-gray-300 mb-4">
    <ul class="flex flex-wrap -mb-px text-xs md:text-sm font-medium text-center text-secondary-700">
      <li class="me-2">
        <Link
          :href="$routes.company()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('company') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('company') ? 'page' : null"
        >
          Company
        </Link>
      </li>
      <li class="me-2">
        <Link
          :href="$routes.clients()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('clients') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('clients') ? 'page' : null"
        >
          Clients
        </Link>
      </li>
      <li class="me-2">
        <Link
          :href="$routes.storage_allocations()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('storage_allocations') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('storage_allocations') ? 'page' : null"
        >
          Storage
        </Link>
      </li>
      <li class="me-2">
        <Link
          :href="$routes.subscriptions()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('subscriptions') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('subscriptions') ? 'page' : null"
        >
          Subscription
        </Link>
      </li>
      <li class="me-2">
        <Link
          :href="$routes.payment_methods()"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            isUrl('payment_methods') ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="isUrl('payment_methods') ? 'page' : null"
        >
          Billing
        </Link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';

const isUrl = (...urls) => {
  let currentUrl = usePage().url.substring(1);

  if (urls[0] === '') {
    return currentUrl === '';
  }

  return urls.filter((url) => currentUrl.startsWith(url)).length;
}
</script>
