<template>
  <PlaylistForm v-model="form" @submit="submit">
    <div
      class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
    >
      <LoadingButton
        :loading="form.processing"
        class="btn-primary"
        type="submit"
      >
        Create Playlist
      </LoadingButton>
    </div>
  </PlaylistForm>
</template>

<script setup>
import LoadingButton from '@/Shared/LoadingButton.vue';
import PlaylistForm from './Form.vue';
import { useForm } from '@inertiajs/vue3'
import * as $routes from '@/routes';

const emit = defineEmits(['success']);

const form = useForm({
  playlist: {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    email_content: "Please help me create a group video tribute by contributing a recorded greeting or sharing a memory."
  }
})

const submit = () => {
      form.post($routes.playlists(), {
        onSuccess: () => {
          emit('success');
          form.reset('playlist');
        },
      });
    }
</script>
