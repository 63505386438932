<template>
  <form @submit.prevent="$emit('submit')">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <TextInput
        v-model="form.playlist.title"
        :errors="form.errors.title"
        class="w-full pb-8 pr-6"
        label="Title"
      />
      <template v-if="!!$env.VITE_ENABLE_PLAYLIST_DUE_DATE">
        <label class="form-label" for="due_date">Due Date (optional)</label>
        <VueTailwindDatepicker
          id="due_date"
          v-model="date"
          as-single
          name="due_date"
          :formatter="formatter"
          class="pr-6 z-[99]"
          input-classes="form-input w-full"
          :placeholder="form.playlist.due_date"
        />
        <div class="text-xs mb-4 mt-1 pr-6">
          Your contributors will be asked to finalize their submission by midnight in your timezone
          <span v-show="!showTz">
            ({{ form.playlist.timezone }})
            <span class="text-primary cursor-pointer" @click="showTz = true">change timezone</span>
          </span>
        </div>
        <TimezoneSelector v-if="showTz" v-model="form.playlist.timezone" class="w-full pb-8 pr-6" />
      </template>

      <label v-if="false" class="form-label">
        Background Color
        <ColorPicker v-model:pureColor="form.playlist.customizations.background_color" />
      </label>

      <label class="form-label">Invitation Message</label>
      <TextareaInput v-model="form.playlist.email_content" class="w-full  pr-6" />
      <div class="text-xs mb-4 mt-1 pr-6">This message will be included, along with instructions, in your invitation emails.</div>
    </div>
    <slot />
  </form>
</template>

<script setup>
import TextInput from '@/Shared/TextInput.vue';
import VueTailwindDatepicker from 'vue-tailwind-datepicker';
import { computed, ref, watch } from 'vue';
const formatter = { date: 'YYYY/M/D', month: 'MMM' }
import { ColorPicker } from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
import TimezoneSelector from '../../Shared/TimezoneSelector.vue';
import TextareaInput from '../../Shared/TextareaInput.vue';


const date = ref()
const bgColor = ref()
const showTz = ref(false)
const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'submit']);

const form = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});

watch([date], () => {
  form.value.playlist.due_date = date.value[0]
})
</script>
