<template>
  <div>
    <template v-if="!acceptedFiles.length || multiple">
      <Record v-model="fromRecorder" @update:model-value="() => { acceptedFiles.push(fromRecorder) }" />
      <div class="border-dashed border p-4 rounded text-center font-semibold text-lg cursor-pointer mt-4" :class=" { 'border-green-700 bg-green-100' : isDragActive }">
        <div v-bind="getRootProps()">
          <CloudArrowUpIcon class="size-8 mx-auto" :class=" { 'text-green-700' : isDragActive }" />
          <input v-bind="getInputProps()">
          <p v-if="isDragActive">
            Drop the files here ...
          </p>
          <p v-else>
            Drag & drop media here or
          </p>
          <span class="btn-secondary mt-4 inline-block">Browse Files</span>
        </div>
      </div>
    </template>
    <div>
      <BulkItem
        v-for="(file, index) in acceptedFiles"
        :key="index"
        ref="items"
        :file
        :form-data="formData"
        :route
        class="p-2"
        @destroy="(toDestroy) => destroy(toDestroy)"
        @success="emit('success')"
      />
    </div>
    <div v-for="(file, index) in rejectedFiles" :key="index" class="p-2 bg-red-100">
      {{ file.file.name }} <FilesizeString :size="file.file.size" />
      <div class="text-red-700 text-xs">
        {{ multiple ? file.errors[index]?.message : rejectedFiles[0]?.errors[0]?.message }}
      </div>
    </div>
    <div v-for="(error, index) in $page.props.errors" :key="index">
      <pre class="text-xs text-red-700 py-2">{{ error }} </pre>
    </div>
  </div>
</template>

<script setup>
import { useDropzone } from "vue3-dropzone";
import { ref } from 'vue';
import FilesizeString from './FilesizeString.vue';
import { CloudArrowUpIcon } from '@heroicons/vue/24/outline';
import BulkItem from '../Pages/Uploads/BulkItem.vue';
import Record from '../Pages/Uploads/Record.vue';

const acceptedFiles = ref([])
const rejectedFiles = ref([])
const items = ref([])
const fromRecorder = ref()

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false
  },
  route: {
    type: String,
    required: true
  },
  formData: {
    type: Object,
    required:true
  }
})
const emit = defineEmits(['success', 'processing']);

const onDrop = (acceptFiles, rejectReasons) => {
  if (props.multiple) {
    acceptedFiles.value = [...acceptedFiles.value, ...acceptFiles]
    rejectedFiles.value = [...rejectedFiles.value, ...rejectReasons]
  } else {
    acceptedFiles.value = acceptFiles
    rejectedFiles.value = rejectReasons
  }
}

const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop,
  accept: ['video/*'],
  multiple: props.multiple,
  maxSize: import.meta.env.VITE_MAX_SIZE_IN_MB*1024*1024
});

const submit = () => {
  if (items.value.length) {
    emit('processing')
    items.value.forEach((i) => i.submit())
  }
}

const destroy = (toDestroy) => {
  let temp = acceptedFiles.value.filter((f) => f.path !== toDestroy);
  acceptedFiles.value = []
  setTimeout(() => {
    acceptedFiles.value = temp
  }, 1)
}

defineExpose({ submit })
</script>
